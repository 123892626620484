import Analytics from "analytics";
import googleTagManager from "@analytics/google-tag-manager";

export function initAnalytics() {
  return Analytics({
    app: "awesome-app",
    plugins: [
      googleTagManager({
        containerId: "GTM-NHGWBSP",
        auth: process.env.NEXT_PUBLIC_GTM_AUTH,
        preview: process.env.NEXT_PUBLIC_GTM_ENV,
      }),
    ],
  });
}
