import "../styles/globals.scss";
import {SessionProvider} from "next-auth/react";
import {SSRProvider} from "react-bootstrap";
import {Router, useRouter} from "next/router";
import NProgress from "nprogress";
import Head from "next/head";
import {initAnalytics} from "../lib/init-gtm";
import {AnalyticsProvider} from "use-analytics";
import {useEffect} from "react";
import {Analytics} from '@vercel/analytics/react';

const analytics = initAnalytics();

NProgress.configure({
    showSpinner: false,
    trickleSpeed: 100,
});
Router.events.on("routeChangeStart", (url) => {
    NProgress.start();
});
Router.events.on("routeChangeComplete", () => {
    NProgress.done();
});
Router.events.on("routeChangeError", () => NProgress.done());

function MyApp({Component, pageProps: {session, ...pageProps}}) {
    useEffect(() => {
        const email = session?.user?.email;
        if (email) {
            analytics.identify(email);
        }
    });

    return (
        <>
            <Head>
                <meta charSet="UTF-8"/>
                {/* Garlic and crescent moon are licensed under the CC-BY 4.0: https://github.com/twitter/twemoji"*/}
                <link
                    rel="apple-touch-icon"
                    sizes="180x180"
                    href="/apple-touch-icon.png"
                />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="32x32"
                    href="/favicon-32x32.png"
                />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="16x16"
                    href="/favicon-16x16.png"
                />
                <link rel="manifest" href="/site.webmanifest"/>
                <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5"/>
                <meta name="msapplication-TileColor" content="#000000"/>
                <meta name="theme-color" content="#000000"/>
            </Head>
            <SSRProvider>
                <SessionProvider session={session}>
                    <AnalyticsProvider instance={analytics}>
                        <Component {...pageProps} />
                    </AnalyticsProvider>
                </SessionProvider>
            </SSRProvider>

            <Analytics/>
        </>
    );
}

export default MyApp;
